import { FullGridWidthDiv } from '@dreamstack/feature-components'
import type { FullBlockFullsizeImageFragment } from '@dreamstack/investors-graphql'
import Image from 'next/legacy/image'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import React from 'react'
import 'twin.macro'

// export const GridWithGapsAndPadding = tw(GridWithGaps)`
//   px-px-24
//   md:px-px-48
//   lg:px-px-64
// `

export const FullsizeImage: FunctionComponent<
  React.PropsWithChildren<{
    block: FullBlockFullsizeImageFragment
  }>
> = ({ block }) => {
  if (!block?.image?.url) return null
  const downloadHref = block?.downloadFile?.url
  const Wrapper = block?.maxWidthParent ? React.Fragment : FullGridWidthDiv
  const ImageElement = (
    <Image
      src={block.image.url}
      width={block.image.width ?? undefined}
      height={block.image.height ?? undefined}
      layout="responsive"
      alt={block.image.description ?? ''}
      quality="100"
    />
  )
  return (
    <Wrapper>
      {!!downloadHref ? (
        <Link href={downloadHref} passHref target="_blank">
          {ImageElement}
        </Link>
      ) : (
        ImageElement
      )}
    </Wrapper>
  )
}
